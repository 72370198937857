import * as React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Avatar,
  CardHeader,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Box } from "@mui/system";
import { Divider } from "@mui/material";
import CardMenu from "../../components/SocialMedia/CardMenu";
import SendMenu from "../../components/SocialMedia/SendMenu";
import {
  formatDistanceToNow,
  parseJSON,
} from "date-fns";
import Comments from "../../components/SocialMedia/Comments";
import LikeMenu from "../../components/SocialMedia/LikeMenu";
import { Link } from "react-router-dom";
import { PostData } from "../../model/FeedDataModel";
import { PostContentViewer } from "../../components/Post/PostViewer";
import { getNavigationLink } from "../../components/SocialMedia/UserNav";
import GridImages from "../../components/ImageGrid";

const muiCardAction = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};
const cssLikeCommentBox = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  paddingY: "2%",
};
const muiIconButtonText = {
  fontWeight: { sm: "400", xs: "400" },
  fontFamily: "Oxygen",
  fontStyle: "normal",
  fontSize: { sm: "12px", xs: "12px" },
  margin: "0px",
  color: "#686868",
  lineHeight: "15px",
  display: { xs: "none", sm: "block", md: "block", lg: "block", xl: "block" },
  //paddingLeft: '10px'
};
const cssLikeComment = {
  fontWeight: 400,
  fontFamily: "Oxygen",
  fontStyle: "regular",
  fontSize: "13px",
  lineHeight: "19px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  color: "#C4C4C4",
};

const headerText2 = {
  width: "65px",
  height: "16px",
  fontFamily: "Oxygen",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "13px",
  lineHeight: "16px",
  color: "#686868",
};

const parentPostContainerCss: React.CSSProperties = {
  padding: "0px 20px 20px",
  border: "1px solid rgb(235, 235, 235)",
  borderRadius: "5px",
};

export interface IStoryProps {
  feedData: any;
  commentData: any;
  onActionPerform: Function;
  editPost?: Function;
  addComment: Function;
  currentUserId: string;
  selectedFeedComment: PostData | null;
  userId: string;
  setSelectedFeedComment: Function;
  deleteComment: Function;
  editComment: Function;
  setCommentPage: Function;
  commentPage: number;
  postLike: Function;
  index: number;
  editImageURL: string | ArrayBuffer;
  selectedFeedLikeId: string;
  setSelectedFeedLikeId: Function;
  anchorEl: boolean;
  setAnchorEl: Function;
  likeData: any;
  setSelectedFile?: Function;
  setEditImageURL: Function;
  onPostShare?: (post: PostData) => void;
}

interface AppState {
  title: string;
  description: string;
  onLikeEnter: boolean;
  onCommentEnter: boolean;
  onLikeHover: boolean;
  likeButtonEnabled: boolean;
  showLikes: boolean;
}
// TODO - Implement 1.Api integration, 2.Pagination, 3.Cleanup
class Story extends React.Component<IStoryProps, AppState> {
  constructor(props: IStoryProps) {
    super(props);
    this.state = {
      title: this.props.feedData?.title,
      description: this.props.feedData?.description,
      onLikeEnter: false,
      onCommentEnter: false,
      onLikeHover: false,
      likeButtonEnabled: true,
      showLikes: false
    };
    this.onInputchange = this.onInputchange.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
  }

  onInputchange(event) {
    if (event.target.name === "title") {
      this.setState({
        title: event.target.value,
      });
    } else {
      this.setState({
        description: event.target.value,
      });
    }
  }

  handleMouseOver(event, value) {
    if (value === "like") {
      this.setState({
        onLikeEnter: true,
      });
    } else if (value === "likeThumb") {
      this.setState({
        onLikeHover: true,
      });
    } else {
      this.setState({
        onCommentEnter: true,
      });
    }
  }

  handleMouseOut(event, value) {
    if (value == "like") {
      this.setState({
        onLikeEnter: false,
      });
    } else {
      this.setState({
        onCommentEnter: false,
      });
    }
  }

  getItemSource = (
    source: string | undefined
  ): { component: "video" | "img"; src: "video" | "audio" | "picture" } => {
    if (!source) {
      return {
        component: "img",
        src: "picture",
      };
    }
    if (
      [
        "MP4",
        "MOV",
        "WMV",
        "AVI",
        "AVCHD",
        "FLV",
        "F4V",
        "SWF",
        "MKV",
        "WEBM",
        "HTML5",
      ].includes(source.split(".")[source.split(".").length - 1].toUpperCase())
    ) {
      return {
        component: "video",
        src: "video",
      };
    } else {
      return {
        component: "img",
        src: "picture",
      };
    }
  };

  renderParentPost(parentPost: PostData) {
    return (
      <div style={parentPostContainerCss}>
        <Box sx={muiCardAction}>
          <CardHeader
            sx={{ paddingX: 0 }}
            avatar={
              <Avatar
                aria-label="created-by-avatar"
                src={parentPost?.created_by?.profilepic}
              >
                {parentPost?.created_by?.name[0]}
              </Avatar>
            }
            title={
              <Typography
                sx={{
                  font: "Oxygen",
                  fontSize: "15px",
                  fontWeight: "700",
                  textDecorationLine: "none",
                }}
                component={Link}
                to={"/viewuser?userid=" + parentPost?.created_by?.id}
              >
                {parentPost?.created_by?.name}
              </Typography>
            }
            subheader={
              <span style={headerText2}>
                {formatDistanceToNow(parseJSON(parentPost?.createdAt))}&nbsp;ago
              </span>
            }
          ></CardHeader>
        </Box>
        <CardContent sx={{ paddingY: "1px !important", paddingX: 0 }}>
          <div>
            {parentPost.title && (
              <div
                style={{
                  marginTop: "8px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.625rem",
                }}
              >
                <PostContentViewer
                  style={{ fontWeight: parentPost.description ? 600 : 400 }}
                  rawContent={parentPost?.title}
                />
              </div>
            )}

            {parentPost.description && (
              <div
                style={{
                  marginTop: "8px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.625rem",
                }}
              >
                <PostContentViewer rawContent={parentPost?.description} />
              </div>
            )}
            {parentPost.media &&
              parentPost.media!.map((x, index) => (
                <CardMedia
                  key={"story-post-" + index}
                  component={this.getItemSource(x.link).component}
                  controls
                  src={this.getItemSource(x.link).src}
                  sx={{
                    width: "100%", marginTop: "10px",
                    objectFit: "contain", 
                    maxHeight: {md:"600px", xs:"450px", sm:"300px"},
                  }}
                  alt={parentPost.title}
                  image={x.link}
                />
              ))}
          </div>
        </CardContent>
      </div>
    );
  }

  renderPostNotAvailableContainer() {
    return (
      <>
        <div style={parentPostContainerCss}>
          <Box sx={muiCardAction}></Box>
          <CardContent sx={{ marginTop: "15px", textAlign: "center" }}>
            <Typography variant="h5"> Post not available</Typography>
          </CardContent>
        </div>
      </>
    );
  }

  render() {
    var story = this.props;
    var storyFeedData = this.props.feedData;
    // Destructuring createdAt from feedData with default value to handle undefined or null
  const { createdAt = null } = this.props.feedData || {};

  // Check if createdAt is null or undefined
  if (!createdAt) {
    // Handle case where createdAt is null or undefined
    return null; // or render a placeholder
  }

  // Parse createdAt string to Date object
  const parsedCreatedAt = parseJSON(createdAt);

  // Check if parsedCreatedAt is a valid date
  if (isNaN(parsedCreatedAt.getTime())) {
    // Handle case where parsedCreatedAt is not a valid date
    return null; // or render a placeholder
  }
    return (
      <React.Fragment key={story?.index}>
        <Card
          variant="outlined"
          sx={{
            width: { xs: "88%", sm: "88%", md: "calc(100% - 40px)" },
            minWidth: { xs: "88%", sm: "88%", md: "70%" },
            maxWidth: { md: "671px" },
            padding: "0 1.25rem 0",
            border: "1px solid #EBEBEB",
            background: "#FFFFFF",
            borderRadius: "12px",
            marginTop: storyFeedData.subType == "suggestions" ? "16px" : "0px"
          }}
        >
          {storyFeedData.subType == "suggestions" && <Box sx={{
            fontFamily: "Oxygen",
            fontSize: "10px",
            textDecorationLine: "none",
            background: "#0f9764",
            position: "absolute",
            padding: "4px 8px",
            right: { xs: "3px", md: "0px" },
            marginTop: "-23px",
            fontStyle: "italic",
            color: "#ffffff",
            fontWeight: 700,
            borderRadius: "5px"
          }}>Suggested for you</Box>}
          {storyFeedData?.media?.length < 1 ? (
            <>
              <Box sx={muiCardAction}>
                <CardHeader
                  sx={{ paddingX: 0 }}
                  avatar={
                    <Avatar
                      aria-label="created-by-avatar"
                      src={storyFeedData?.created_by?.profilepic}
                    >
                      {storyFeedData?.created_by?.name[0]}
                    </Avatar>
                  }
                  title={
                    <>
                      <Typography
                        color={"#1B1C28"}
                        sx={{
                          font: "Oxygen",
                          fontSize: "14px",
                          fontWeight: "700",
                          lineHeight: "18px",
                          textDecorationLine: "none",
                        }}
                        component={Link}
                        // to={"/viewuser?userid=" + storyFeedData?.created_by?.id}
                        to={getNavigationLink(storyFeedData?.created_by)}
                      >
                        {storyFeedData?.created_by?.name.length < 20 ? storyFeedData?.created_by?.name : storyFeedData?.created_by?.name.substring(20, 0) + "..."}
                      </Typography>
                      {storyFeedData.parent && (
                        <Typography
                          sx={{ color: headerText2.color, marginLeft: "10px" }}
                        >
                          shared a post
                        </Typography>
                      )}
                    </>
                  }
                ></CardHeader>
                <div style={{ display: "flex" }}>
                  <div>
                    <Card
                      sx={{
                        fontFamily: "Oxygen",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "13px",
                        lineHeight: "16px",
                        color: "#686868",
                        marginTop: "18px",
                        marginLeft: { xs: 1 },
                        width: { md: "200px", xs: "110px" },
                        height: { md: "40px", xs: "50px" },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: { xs: "column", md: "row" },
                          gap: { xs: 0.5 },
                          padding: "8px",
                          marginTop: { md: "5px" },
                        }}
                      >
                        <Box sx={{
                          display: { xs: "flex" },
                          gap: { xs: 1 },
                          justifyContent: { xs: "center" },
                        }}>
                          <svg
                            width="12"
                            height="13"
                            viewBox="0 0 12 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.25 5.33333H0.75M8.33333 0.666664V3M3.66667 0.666664V3M3.55 12.3333H8.45C9.43009 12.3333 9.92014 12.3333 10.2945 12.1426C10.6238 11.9748 10.8915 11.7071 11.0593 11.3778C11.25 11.0035 11.25 10.5134 11.25 9.53333V4.63333C11.25 3.65324 11.25 3.16319 11.0593 2.78885C10.8915 2.45956 10.6238 2.19185 10.2945 2.02407C9.92014 1.83333 9.43009 1.83333 8.45 1.83333H3.55C2.56991 1.83333 2.07986 1.83333 1.70552 2.02407C1.37623 2.19185 1.10852 2.45956 0.940739 2.78885C0.75 3.16319 0.75 3.65324 0.75 4.63333V9.53333C0.75 10.5134 0.75 11.0035 0.940739 11.3778C1.10852 11.7071 1.37623 11.9748 1.70552 12.1426C2.07986 12.3333 2.56991 12.3333 3.55 12.3333Z"
                              stroke="#686868"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          {new Date(
                            parseJSON(storyFeedData?.createdAt)
                          ).toLocaleDateString("en-IN", {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          })}
                        </Box>
                        <Box sx={{
                          display: { md: "block", xs: "none" }
                        }}>
                          <svg
                            width="1"
                            height="19"
                            viewBox="0 0 1 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <line
                              x1="0.5"
                              y1="2.18557e-08"
                              x2="0.499999"
                              y2="19"
                              stroke="#EBEBEB"
                            />
                          </svg>
                        </Box>
                        <Box sx={{
                          display: { xs: "flex" },
                          gap: { xs: 1 },
                          justifyContent: { xs: "center" },
                        }}>
                          <svg
                            width="16"
                            height="15"
                            viewBox="0 0 16 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8 3.5V7.5L10.6667 8.83334M14.6667 7.5C14.6667 11.1819 11.6819 14.1667 8 14.1667C4.3181 14.1667 1.33333 11.1819 1.33333 7.5C1.33333 3.8181 4.3181 0.833336 8 0.833336C11.6819 0.833336 14.6667 3.8181 14.6667 7.5Z"
                              stroke="#686868"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          {new Date(
                            parseJSON(storyFeedData?.createdAt)
                          ).toLocaleTimeString("en-IN", {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })}
                        </Box>
                      </Box>
                    </Card>
                  </div>
                  <div style={{ marginTop: "15px" }}>
                    {story?.currentUserId === storyFeedData?.created_by?.id ? (
                      <CardMenu
                        index={story?.index}
                        onActionPerform={story.onActionPerform}
                        postId={storyFeedData?.id}
                      />
                    ) : null}
                  </div>
                </div>
              </Box>
              <svg
                width="100%"
                height="1"
                viewBox="0 0 626 1"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line y1="0.5" x2="626" y2="0.5" stroke="#0F9764" />
              </svg>
            </>
          ) : (
            <Box sx={muiCardAction}>
              <CardHeader
                sx={{ paddingX: 0 }}
                avatar={
                  <Avatar
                    aria-label="created-by-avatar"
                    src={storyFeedData?.created_by?.profilepic}
                  >
                    {storyFeedData?.created_by?.name[0]}
                  </Avatar>
                }
                title={
                  <Typography
                    sx={{
                      font: "Oxygen",
                      fontSize: "15px",
                      fontWeight: "700",
                      textDecorationLine: "none",
                    }}
                    component={Link}
                    to={"/viewuser?userid=" + storyFeedData?.created_by?.id}
                  >
                    {storyFeedData?.created_by?.name}
                  </Typography>
                }
                subheader={
                  <span style={headerText2}>
                    {formatDistanceToNow(parsedCreatedAt)}
                    &nbsp;ago
                  </span>
                }
              ></CardHeader>
              <div style={{ marginTop: "15px" }}>
                {story?.currentUserId === storyFeedData?.created_by?.id ? (
                  <CardMenu
                    index={story?.index}
                    onActionPerform={story.onActionPerform}
                    postId={storyFeedData?.id}
                  />
                ) : null}
              </div>
            </Box>
          )}

          <CardContent sx={{ paddingY: "1px !important", paddingX: 0 }}>
            <div>
              {storyFeedData.title && (
                <div
                  style={{
                    marginTop: "8px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.625rem",
                  }}
                >
                  <PostContentViewer
                    style={{
                      fontWeight: storyFeedData.description ? 600 : 400,
                    }}
                    rawContent={storyFeedData?.title}
                  />
                </div>
              )}

              {storyFeedData.description && (
                <div
                  style={{
                    marginTop: "8px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.625rem",
                  }}
                >
                  <PostContentViewer
                    rawContent={storyFeedData?.description}
                    isMediaPresent={storyFeedData.media.length > 0}
                    renderContentAsHtml={storyFeedData.type === "News"}
                  />
                </div>
              )}
              {
                storyFeedData.media && storyFeedData.media?.length > 0 && <GridImages title={storyFeedData?.id ?? storyFeedData.title} mediaList={storyFeedData.media} />
                // storyFeedData.media!.map((x, index) => (
                //   <CardMedia
                //     key={"story-feed-" + index}
                //     component={this.getItemSource(x.link).component}
                //     controls
                //     src={this.getItemSource(x.link).src}
                //     sx={{ width: "100%", marginTop: "10px" }}
                //     alt={storyFeedData.title}
                //     image={x.link}
                //   />
                // ))
              }
            </div>
            {storyFeedData.parent &&
              (storyFeedData.parent.created_by
                ? this.renderParentPost(storyFeedData.parent)
                : this.renderPostNotAvailableContainer())}
          </CardContent>

          <Box sx={cssLikeCommentBox}>
            <Typography
              onMouseEnter={(e) => this.handleMouseOver(e, "like")}
              onMouseLeave={(e) => this.handleMouseOut(e, "like")}
              onClick={(event) =>
                this.setState({ showLikes: true })
              }
              sx={{
                fontWeight: 400,
                fontFamily: "Oxygen",
                fontStyle: "regular",
                fontSize: "13px",
                lineHeight: "16px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                cursor: "pointer",
                borderBottom: this.state.onLikeEnter
                  ? "1px solid #0f9764"
                  : "none",
                color: this.state.onLikeEnter ? "#0f9764" : "#C4C4C4",
              }}
            >
              &nbsp;{storyFeedData?.likes_count}&nbsp;Likes&nbsp;&nbsp;&nbsp;
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Typography
              onMouseEnter={(e) => this.handleMouseOver(e, "comment")}
              onMouseLeave={(e) => this.handleMouseOut(e, "comment")}
              onClick={() => story?.setSelectedFeedComment(storyFeedData)}
              sx={{
                fontWeight: 400,
                fontFamily: "Oxygen",
                fontStyle: "regular",
                fontSize: "13px",
                lineHeight: "16px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                cursor: "pointer",
                borderBottom: this.state.onCommentEnter
                  ? "1px solid #0f9764"
                  : "none",
                color: this.state.onCommentEnter ? "#0f9764" : "#C4C4C4",
              }}
            >
              &nbsp;&nbsp;&nbsp;{storyFeedData?.comments_count}
              &nbsp;Comments&nbsp;
            </Typography>
          </Box>
          <Divider orientation="horizontal" flexItem />
          <CardActions
            // disableSpacing
            sx={muiCardAction}
          >
            <IconButton
              disabled={!this.state.likeButtonEnabled}
              onMouseEnter={(e) => this.handleMouseOver(e, "likeThumb")}
              sx={{
                cursor: "pointer",
                color: storyFeedData?.like_type === "like" ? "#0f9764" : "",
              }}
              onClick={() => {
                this.setState({
                  likeButtonEnabled: false,
                });
                story?.postLike(
                  storyFeedData?.id,
                  storyFeedData?.like_type === "like" ? 1 : 0,
                  storyFeedData?.type,
                  story?.index,
                  () => {
                    this.setState({
                      likeButtonEnabled: true,
                    });
                  }
                );
              }}
              aria-label="like"
            >
              <img
                src={`${storyFeedData?.like_type === "like"
                  ? "news-like-filled.svg"
                  : "news-like.svg"
                  }`}
                alt="Stocknocks"
              />
              <Typography sx={muiIconButtonText}>
                &nbsp;&nbsp;&nbsp;Like
              </Typography>
            </IconButton>
            <IconButton
              onClick={() => story?.setSelectedFeedComment(storyFeedData)}
              aria-label="comment"
            >
              <img src="news-comment.svg" alt="Stocknocks" />

              <Typography sx={muiIconButtonText}>
                &nbsp;&nbsp;&nbsp;Comment
              </Typography>
            </IconButton>
            <IconButton
              aria-label="share"
              onClick={() => this.props.onPostShare!(storyFeedData)}
            >
              <img src="share.svg" alt="Stocknocks" />
              <Typography sx={muiIconButtonText}>
                &nbsp;&nbsp;&nbsp;Share
              </Typography>
            </IconButton>
            <SendMenu  url={`/community?feedkey=${storyFeedData.id}`} />
          </CardActions>
          {story?.selectedFeedComment?.id === storyFeedData?.id ? (
            <div style={{ paddingBottom: "10px" }}>
              <Comments
                commentData={story?.commentData}
                addComment={story?.addComment}
                postId={storyFeedData?.id}
                postType={storyFeedData?.type}
                editComment={story?.editComment}
                deleteComment={story?.deleteComment}
                setCommentPage={story?.setCommentPage}
                commentPage={story?.commentPage}
                recordIndex={story?.index}
                currentUserId={story?.currentUserId}
              />
            </div>
          ) : null}
        </Card>
        {
          storyFeedData?.likes_count && this.state.showLikes ? (
            <LikeMenu
              recordId={storyFeedData?.id}
              recordType={storyFeedData?.type}
              onClose={() => this.setState({ showLikes: false })}
            />
          ) : null
        }
      </React.Fragment >
    );
  }
}

// export default withStyles(imageGridStyle)(Story);

export default Story;
