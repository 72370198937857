import React, { useRef } from "react";
import {
    Box,
    Button,
    CardActions,
    Grid,
    Link,
    Paper,
    Tabs,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material";
import Card from "@mui/material/Card";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import {
    useParams,
    // Link, 
    // useLocation, 
    useSearchParams
} from "react-router-dom";
// import { Utility } from "../../utility/Utility";
import { CompanyOwner, UnlistedCompanyPrice } from "../../model/UnlistedCompanyBasicDetails";
import { getCompanyById, getUnlistedCompanySummary, getUnlistedCompanyPrice, getUnListedFinancialsReports, getUnlistedCompanySeo } from "../../services/CompanyService";
import { amIFollowing } from "../../services/SocialMediaFollow";
import { blue, red, green, purple, yellow } from "@mui/material/colors";
import { postFollowAPI, postUnFollowAPI } from "../../services/SocialMediaFollow";
import { FollowRequest } from "../../model/FollowModel";
import { Utility } from "../../utility/Utility";
import { Notification } from "../../model/Notification";
import GetUnlistedBasicDetails from "./BasicDetails";
import { UnlistedFinanceSummary } from "../../model/UnlistedFinanceSummaryResponseModel";
import IFrame from "./IFrame";
import useStyle from "./Styles";
import AddIcon from '@mui/icons-material/Add';
import { NumberExtensions } from "../../utility/NumberExtensions";
import { DateExtensions } from "../../utility/DateExtensions";
import CircularIndeterminate from "../../components/Loading/Loading";
import PageRedirectionDialog from "../../components/QuetionDialog/PageRedirectionDialog";
import { MobileAdvert } from "../Layout/MobileAdvert";
import OwnCompanyDialog from "../../components/QuetionDialog/UnlistedOwnCompanyDialog";
import PostSectionComponent from "../../components/CompanyPosts/PostSection";
import IFrameVideo from "./IFrameVideo";
import { useSelector } from "react-redux";
import { ReduxStore } from "../../model/ReduxStore";
import { InView } from 'react-intersection-observer';
import VerifiedIcon from '@mui/icons-material/Verified';
import { CopyLinkButton } from "../../components/Common/CopyLinkButton";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { RoleUtility } from "../../utility/RoleUtility";
import { getInstaFinanceRefresh } from "../../services/AdminPanelService";
import { isLogoAvailable } from "../../services/FileService";
import { StockKnocksGrid } from "../UnListed/Grid";



const getRandomColor = () => {
    const colors = [blue, red, green, purple, yellow];
    return colors[Math.floor(Math.random() * 5 + 0)];
}

const UnlistedCompanyOverview = () => {
    const isSM = useMediaQuery("(min-width:900px)");
    const classes = useStyle();
    const [displayType, setDisplayType] = React.useState<number>(-1);
    const [mainTab, setMainTab] = React.useState<string>('basic_details');
    const [childTab, setChildTab] = React.useState<string>('basicdetails');
    const [color, setColor] = React.useState<any>(null);
    const [logoBox, setLogoBox] = React.useState<any>(null);
    const [companyShort, setCompanyShort] = React.useState<any>({});
    const [companySummary, setCompanySummary] = React.useState<any>(null);
    const [tradable, setTradable] = React.useState<boolean>(false);
    const [price, setPrice] = React.useState<UnlistedCompanyPrice | null>(null);
    // const [companyLongName, setCompanyLongName] = useState('');
    // const [hasWebsite, setHasWebSite] = React.useState<boolean>(false);
    const [companyFinanceS, setCompanyFinanceS] = React.useState<UnlistedFinanceSummary>({
        has_data: false,
        show_balance_sheet: false,
        show_profit_and_loss: false,
        show_ratios: false,
        balance_sheet: null,
        profit_and_loss: null,
        ratios: null,
        type: 0
    });
    const [companyFinanceC, setCompanyFinanceC] = React.useState<UnlistedFinanceSummary>({
        has_data: false,
        show_balance_sheet: false,
        show_profit_and_loss: false,
        show_ratios: false,
        balance_sheet: null,
        profit_and_loss: null,
        ratios: null,
        type: 1
    });
    const [companyFinanceLoading, setCompanyFinanceLoading] = React.useState<boolean>(true);
    const [showFinanceTab, setShowFinanceTab] = React.useState<boolean>(false);
    const [isFollow, setFollow] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [showPageRedirectionDialog, setShowPageRedirectionDialog] = React.useState<boolean>(false);
    const [showMoreDetailsDialog, setShowMoreDetailsDialog] = React.useState<boolean>(false);
    const [showOwnCompanyDialog, setShowOwnCompanyDialog] = React.useState<boolean>(false);
    const currentUser = useSelector((state: ReduxStore) => state.userData.user);
    const [owner, setOwner] = React.useState<CompanyOwner | null>(null);

    const [showAdminMenu, setShowAdminMenu] = React.useState<boolean>(false);

    const appBarBottom = document.querySelector<HTMLElement>("#stockknocks-appbar")?.getBoundingClientRect()?.bottom || 0;
    const refContent = useRef<null | HTMLDivElement>(null);

    const { coname, cocode } = useParams();

    const handlePageRedirectionDialogClose = () => {
        setShowPageRedirectionDialog(false);
    }

    const handleMoreDetailsDialogClose = () => {
        setShowMoreDetailsDialog(false);
    }

    const handleConfirmClick = () => {
        window.open('https://trade.altiusinvestech.com/signup?utm=StockKnockss');
        setShowPageRedirectionDialog(false);
    }

    const handleOwnCompanyDialogClose = () => {
        setShowOwnCompanyDialog(false);
    }

    const handleMoreDetailsClick = () => {
        let coName = companyShort?.companyName;
        if (coName) {
            coName = coName.toLowerCase().replace(/[.;-]/g, '').replaceAll(' ', '-');
        }
        window.open(`https://www.instafinancials.com/company/${coName}/${companyShort?.CIN}?ReferralCode=stockknocksv`);
        setShowMoreDetailsDialog(false);
    }



    let [searchParams] = useSearchParams();
    // const getCode = (searchParams) => {
    //     console.log(cocode);
    //     console.log(searchParams.get('code'));
    //     if (cocode && cocode.length > 0) {
    //         return cocode;
    //     }
    //     else {
    //         return searchParams.get('code');
    //     }
    // }
    const code: string | null = cocode ?? searchParams.get('code');

    React.useEffect(() => {
        if (RoleUtility.isAdmin(currentUser?.role) === true) {
            setShowAdminMenu(true);
        }
    }, []);

    React.useEffect(() => {
        if (code)
            getUnlistedCompanySeo(code).then(x => {
                if (x)
                    document.title = x;
                else
                    document.title = "Best investment research platforms | Stock technical analysis India";
            })
    }, [code]);

    React.useLayoutEffect(() => {
        setDisplayType(-1);
        setTradable(false);
        setFollow(false);
        setLoading(true);
        setCompanySummary(null);
        setCompanyFinanceC({
            has_data: false,
            show_balance_sheet: false,
            show_profit_and_loss: false,
            show_ratios: false,
            balance_sheet: null,
            profit_and_loss: null,
            ratios: null,
            type: 1
        });
        setCompanyFinanceS({
            has_data: false,
            show_balance_sheet: false,
            show_profit_and_loss: false,
            show_ratios: false,
            balance_sheet: null,
            profit_and_loss: null,
            ratios: null,
            type: 0
        });
        setCompanyFinanceLoading(true);
        getCompanyById(code).then(company => {
            setFollow(false);
            setCompanyShort(company);
            // setCompanyLongName(company.companyName);
            setColor(getRandomColor()[900]);
            company?.listedUnlisted === 'Listed' ? ValidateListedLogo(company) : ValidateLogo(company);
            amIFollowing(company._id, "UnlistedCompany").then(follow => {
                if (follow && follow.length > 0) {
                    setFollow(true);
                }
            });

            let dType = company.type;
            if (NumberExtensions.isNumber(dType)) {
                setDisplayType(Number(dType));
            }

            if (company?.trade_enabled === true || company?.trade_enabled?.toString() === "true") {
                setTradable(true);
            }
            else if (tradable !== false) {
                setTradable(false);
            }

            if (company.hasOwner === true && company.owner) {
                setOwner(company.owner);
            }
            else if (owner) {
                setOwner(null);
            }
        }).finally(() => {
            // setLoading(false);
        });
    }, [code]);

    React.useEffect(() => {
        if (companyShort?.CIN) {
            if (displayType === -1) {
                // Do Nothing
            }
            else if (displayType === 9) {
                getUnlistedCompanySummary(companyShort.CIN).then(summary => {
                    setCompanySummary(summary);
                    setCompanyFinanceLoading(false);
                    if (summary && summary.financials_summary && summary.financials_summary.financials
                        && (summary.financials_summary.columns && Array.isArray(summary.financials_summary.columns) && summary.financials_summary.columns.length > 0)
                        && (summary.financials_summary.keys && Array.isArray(summary.financials_summary.keys) && summary.financials_summary.keys.length > 0)) {
                        setShowFinanceTab(true);
                    }
                }).finally(() => {
                    setLoading(false);
                });
            }
            else {
                getUnListedFinancialsReports(companyShort.CIN).then(finData => {
                    if (finData && finData.length > 0) {
                        finData.forEach((li) => {
                            if (li && li.type === 0) {
                                setCompanyFinanceS(li);
                            }
                            if (li && li.type === 1) {
                                setCompanyFinanceC(li);
                            }
                        });
                        setCompanyFinanceLoading(false);
                    }
                }).finally(() => {
                    setLoading(false);
                });
            }
        }
    }, [companyShort.CIN, displayType]);

    React.useEffect(() => {
        if (tradable === true) {
            getUnlistedCompanyPrice(companyShort.CIN).then(pricing => {
                if (pricing) {
                    setPrice(pricing);
                }
            });
        }
    }, [companyShort.CIN, tradable]);

    function stickyTabHandler(inView: any, entry: any) {
        var parentElem = document.querySelector<HTMLElement>("#company-section-header");
        var stickyElem = document.querySelector<HTMLElement>("#main-tab");
        var stickyAppBar = document.querySelector<HTMLElement>("#stockknocks-appbar");
        var tempAppBarPos = (stickyAppBar ? stickyAppBar.getBoundingClientRect().bottom : 0);

        if (inView === false) {
            if (stickyElem && stickyElem.style) {
                stickyElem.style.position = "fixed";
                stickyElem.style.top = `${tempAppBarPos - 3}px`;
                // stickyElem.style["z-index"] = "1100 !important";
                stickyElem.style.background = "#F9F9F9";
                stickyElem.style.width = parentElem?.offsetWidth ? `${parentElem?.offsetWidth - 4}px` : 'auto';
                stickyElem.style.borderBottom = '1px solid #EBEBEB';
                stickyElem.style.paddingBottom = '7px';
                stickyElem.style.borderLeft = '1px solid #EBEBEB';
                stickyElem.style.borderRight = '1px solid #EBEBEB';
            }
        } else if (inView === true) {
            if (stickyElem && stickyElem.style) {
                stickyElem.style.position = "relative";
                stickyElem.style.top = "initial";
                stickyElem.style.borderBottom = 'none';
                stickyElem.style.paddingBottom = '0px';
                stickyElem.style.borderLeft = 'none';
                stickyElem.style.borderRight = 'none';
            }
        }
    }

    function handleTabChangeScroll() {
        refContent.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleMainTabChange = (event: React.SyntheticEvent, newValue: string) => {
        if (newValue && !(mainTab === newValue)) {
            setMainTab(newValue);
            handleTabChangeScroll();
        }
    };

    const handleChildTabChange = (event: React.SyntheticEvent, newValue: string) => {
        if (newValue && !(childTab === newValue)) {
            setChildTab(newValue);
        }
    };

    async function ValidateLogo(co_view) {
        if (co_view && co_view.logo && co_view.logo.length > 0) {
            setLogoBox(
                <Box
                    component="img"
                    alt="logo"
                    src={co_view.logo}
                    sx={{
                        padding: '10px',
                        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                        width: { xl: "70px", lg: "70px", md: "70px", sm: "70px", xs: "50px" },
                        height: { xl: "70px", lg: "70px", md: "70px", sm: "70px", xs: "50px" },
                        color: color,
                        backgroundColor: "#FFFFFF",
                        borderRadius: '4px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                </Box>
            );
        }
        else {
            setLogoBox(
                <Box
                    sx={{
                        padding: '10px',
                        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                        width: { xl: "70px", lg: "70px", md: "70px", sm: "70px", xs: "50px" },
                        height: { xl: "70px", lg: "70px", md: "70px", sm: "70px", xs: "50px" },
                        color: color,
                        backgroundColor: "#FFFFFF",
                        borderRadius: '4px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Typography title={co_view.companyName} variant={co_view.companyName && co_view.companyName.length > 7 ? "h2" : "h3"} sx={{ fontWeight: 'bold', overflow: 'hidden' }}>
                        {co_view.companyName ? (co_view.companyName.length > 7 ? co_view.companyName[0] : co_view.companyName) : companyShort?.companyName?.[0]}
                    </Typography>

                </Box>
            );
        }
    };

    async function ValidateListedLogo(co_view) {
        if (co_view.logo && co_view.logo.trim()?.length > 0) {
            await setLogoBox(
                <Box
                    component="img"
                    alt="logo"
                    src={co_view.logo}
                    sx={{
                        padding: '10px',
                        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                        width: { xl: "70px", lg: "70px", md: "70px", sm: "70px", xs: "50px" },
                        height: { xl: "70px", lg: "70px", md: "70px", sm: "70px", xs: "50px" },
                        color: color,
                        backgroundColor: "#FFFFFF",
                        borderRadius: '4px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                </Box>
            );
        }
        else {
            //co_view && fetch(co_view.logo_url, { mode: 'no-cors', method: 'HEAD' })
            let logo_url = `https://storage.googleapis.com/sockknocks-stock-assets/logos/${co_view.isin?.toUpperCase()}.png`;
            co_view.isin && co_view.isin.length && isLogoAvailable(logo_url)
                .then(async (res) => {
                    if (res) {
                        //if (res.status === 200 || res.status === 0) {
                        await setLogoBox(
                            <Box
                                component="img"
                                alt="logo"
                                src={logo_url}
                                sx={{
                                    padding: '10px',
                                    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                                    width: { xl: "70px", lg: "70px", md: "70px", sm: "70px", xs: "50px" },
                                    height: { xl: "70px", lg: "70px", md: "70px", sm: "70px", xs: "50px" },
                                    color: color,
                                    backgroundColor: "#FFFFFF",
                                    borderRadius: '4px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    objectFit: 'cover'
                                }}
                            >
                            </Box>
                        );
                    }
                    else {
                        await setLogoBox(
                            <Box
                                sx={{
                                    padding: '10px',
                                    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                                    width: { xl: "70px", lg: "70px", md: "70px", sm: "70px", xs: "50px" },
                                    height: { xl: "70px", lg: "70px", md: "70px", sm: "70px", xs: "50px" },
                                    color: color,
                                    backgroundColor: "#FFFFFF",
                                    borderRadius: '4px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography title={co_view?.nse} variant={co_view?.nse && co_view?.nse?.length > 7 ? "h2" : "h6"} sx={{ fontWeight: 'bold', overflow: 'hidden' }}>
                                    {co_view?.nse ? (co_view?.nse?.length > 7 ? co_view?.nse[0] : co_view?.nse) : co_view?.companyName?.[0]}
                                </Typography>
                            </Box>
                        );
                    }

                }).catch(ex => {
                    setLogoBox(
                        <Box
                            sx={{
                                padding: '10px',
                                boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                                width: '100px',
                                height: '100px',
                                position: 'absolute',
                                top: '70px',
                                left: '20px',
                                color: color,
                                backgroundColor: "#FFFFFF",
                                borderRadius: '4px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Typography title={co_view?.nse} variant={co_view?.nse && co_view?.nse?.length > 7 ? "h2" : "h6"} sx={{ fontWeight: 'bold', overflow: 'hidden' }}>
                                {co_view?.nse ? (co_view?.nse?.length > 7 ? co_view?.nse[0] : co_view?.nse) : co_view?.companyName?.[0]}
                            </Typography>
                        </Box>
                    );
                });
        }
    };

    const getContent = (subTab: string): JSX.Element => {
        if (subTab === mainTab) {
            switch (subTab) {
                case "posts":
                    // return GetUnlistedPosts(companyShort);
                    return <PostSectionComponent co_id={code || ''} co_userid={owner?.id || ''} />;
                case "website":
                    return IFrame(companyShort);
                case "video":
                    return IFrameVideo(companyShort);
                default:
                    return <React.Fragment></React.Fragment>;
            }
        }
        else {
            return <React.Fragment></React.Fragment>;
        }

        // if (subTab === mainTab) {
        //     switch (subTab) {
        //         case "basic_details":
        //             return GetUnlistedBasicDetails(companyShort, companyFinance);
        //         case "posts":
        //             return GetUnlistedPosts(companyShort);

        //         default:
        //             return <React.Fragment></React.Fragment>;
        //     }
        // }
        // else {
        //     return <React.Fragment></React.Fragment>;
        // }
    }

    const toggleFollow = async () => {
        setFollow(!isFollow);
        const followingData: FollowRequest = { following_id: companyShort._id, following_type: "UnlistedCompany" }
        isFollow ? await postUnFollowAPI(followingData) : await postFollowAPI(followingData);
        const message: string = isFollow ? "You've unfollowed " + companyShort.companyName : "You've followed " + companyShort.companyName;
        const notification: Notification = { message, type: "success" }
        Utility.notify(notification)
    }

    const onPageRedirectClick = () => {
        setShowPageRedirectionDialog(true);
    }

    const onMoreDetailsClick = () => {
        setShowMoreDetailsDialog(true);
    }

    const onOwnCompanyClick = () => {
        setShowOwnCompanyDialog(true);
    }

    const getCompanyPreviewUrl = () => {
        let location = window.location;
        return `${location.href.substring(0, location.href.indexOf(location.pathname))}/preview/unlisted/${companyShort?.companyName.replaceAll(" ", "-")}`;
    }

    const delay = ms => new Promise(res => setTimeout(res, ms));

    async function refreshInstafinancialsAndReload() {
        let refreshResp = await getInstaFinanceRefresh(companyShort.CIN, companyShort.companyName, companyShort?._id);
        console.log(refreshResp);
        if (refreshResp && Array.isArray(refreshResp.summary) && refreshResp.summary.length > 0) {
            let itemResp = refreshResp.summary[0]?.response?.toLowerCase();
            if (itemResp.includes("skipped")) {
                const notification: Notification = {
                    message: `Refresh job skipped. Data already available.`,
                    type: 'success',
                };
                Utility.notify(notification);
            }
            else if (itemResp.includes("no data found")) {
                const notification: Notification = {
                    message: `Data not found for CIN: ${companyShort.CIN} on InstaFinancials.`,
                    type: 'success',
                };
                Utility.notify(notification);
            }
            else {
                const notification: Notification = {
                    message: `Data refreshed for CIN: ${companyShort.CIN} from InstaFinancials.`,
                    type: 'success',
                };
                Utility.notify(notification);
                await delay(2000);
                window.location.reload();
            }
        }
        else {
            const notification: Notification = {
                message: 'Company data refresh failed.',
                type: 'error',
            };
            Utility.notify(notification);
        }
    }

    const getCompanyHeader = () => {
        return <Paper id='company-header' elevation={0}
            sx={{
                marginY: '0px', marginX: '0px',
                backgroundColor: '#F9F9F9',
                border: '1px solid #EBEBEB', borderRadius: '8px',
            }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '24px' }}>
                <Grid container spacing={0.1}>
                    <Grid item container sm={12} md={9} sx={{ display: 'flex', flexDirection: "row" }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', padding: '5px' }}>
                            {logoBox}
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', padding: { md: "0px 24px", xs: "0px 8px" }, maxWidth: { xs: "calc(100% - 100px)", md: "auto" } }}>
                            <Box>
                                <Box flexDirection="row" display="flex">
                                    <Typography color="#12626C" sx={{ fontSize: { xs: "16px", md: '16px' }, fontWeight: '700' }} variant="h6">
                                        {companyShort?.companyName}
                                    </Typography>
                                    <CopyLinkButton style={{ paddingY: "0px" }} locationHash='' linkToCopy={getCompanyPreviewUrl()} />
                                </Box>
                                <Typography sx={{ color: "#686868", fontSize: "14px" }}>
                                    {companyShort?.companySubCategory} , {companyShort?.registeredState}
                                </Typography>
                                <CardActions style={{ padding: "8px 0px 0px 0px" }}>
                                    {(!((owner?.id || '') === (currentUser?._id || ''))) && <Box>
                                        <Button
                                            onClick={toggleFollow}
                                            sx={{ border: '1px solid', paddingLeft: "20px", fontWeight: 700, wordWrap: 'normal', fontSize: "14px" }}
                                            variant="outlined"
                                            startIcon={<AddIcon />}>
                                            {isFollow ? 'Unfollow' : 'Follow'}
                                        </Button>
                                    </Box>}
                                    {(!(owner && owner.id?.length > 0)) && <Box >
                                        <Button
                                            onClick={onOwnCompanyClick}
                                            sx={{ fontSize: '16px', padding: "8px 20px", fontWeight: "700", height: "40px" }}
                                            size="small"
                                            variant="contained">
                                            Own This Page
                                        </Button>
                                    </Box>}
                                    {(owner && owner.id?.length > 0) && <Tooltip title={"Stockknocks Verified"}>
                                        <Box
                                            sx={{
                                                border: '1px solid #0f9764', borderRadius: '24px',
                                                // paddingLeft: "20px", 
                                                fontWeight: 700, wordWrap: 'normal', fontSize: "14px",
                                                height: '35px', width: '100px',
                                                display: 'flex', justifyContent: 'center', alignItems: "center",
                                            }}>
                                            <VerifiedIcon color="primary" />
                                            <Typography sx={{
                                                fontSize: '14px', fontWeight: "700", height: "35px", wordWrap: 'normal',
                                                paddingLeft: "8px",
                                                color: '#0f9764',
                                                display: 'flex', justifyContent: 'center', alignItems: 'center'
                                            }}>Verified</Typography>
                                        </Box>
                                    </Tooltip>}
                                    {(showAdminMenu === true) && <Tooltip title={"Refresh Instafinancials Data"}>
                                        <Box
                                            sx={{
                                                fontWeight: 700, wordWrap: 'normal', fontSize: "14px",
                                                height: '35px', width: '100px',
                                                display: 'flex', justifyContent: 'center', alignItems: "center",
                                            }}
                                        >
                                            <Button
                                                onClick={refreshInstafinancialsAndReload}
                                                sx={{
                                                    border: '1px solid red', paddingLeft: "5px", fontWeight: 700, wordWrap: 'normal',
                                                    color: 'red',
                                                    fontSize: '12px', lineHeight: '12px',
                                                    '&:hover, &:active': { borderColor: 'red' }
                                                }}
                                                variant="outlined"
                                                startIcon={
                                                    <AutorenewIcon
                                                        sx={{ color: 'red', backgroundColor: 'transparent', border: 'none' }}
                                                    />}>
                                                Refresh Financials
                                            </Button>
                                        </Box>
                                    </Tooltip>}
                                </CardActions>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item container sm={12} md={3} sx={{ justifyContent: "right" }}>
                        <Box sx={{ display: "flex", justifyContent: { md: 'right', xs: 'right' }, width: '100%' }}>
                            {price && <Grid container spacing={0} sx={{ display: 'flex' }}>

                                <Grid item sm={12} md={12} sx={{ display: 'flex', justifyContent: 'right', margin: '0px' }}>
                                    <Typography
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            fontWeight: '700',
                                            fontSize: "16px",
                                            color: '#0f9764'
                                        }}
                                    >
                                        {`Buy: ${price?.buy_price || "NA"}`}
                                    </Typography>

                                </Grid>
                                <Grid item sm={12} md={12} sx={{ display: 'flex', justifyContent: 'right', margin: '0px' }}>
                                    <Typography
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            fontWeight: '700',
                                            fontSize: "16px",
                                            color: '#fb645c'
                                        }}
                                    >
                                        {`Sell: ${price?.sell_price || "NA"}`}
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} md={12} sx={{ display: 'flex', justifyContent: 'right' }}>
                                    <Box >
                                        <Button
                                            onClick={onPageRedirectClick}
                                            sx={{ fontSize: '16px', padding: "8px 20px", fontWeight: "700", height: "40px" }}
                                            size="small"
                                            variant="contained">
                                            Trade
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item sm={12} md={12} sx={{ display: 'flex', justifyContent: 'right', marginTop: '5px' }}>
                                    <Typography
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            fontWeight: '400', fontSize: '10px', fontStyle: 'normal',
                                            lineHeight: "13px",
                                            color: '#686868'
                                        }}
                                    >
                                        {`Price Date: ${DateExtensions.getDateStringUppercaseMonthFromString(price.updatedAt, ' ', 'NA')}`}
                                    </Typography>
                                </Grid>
                            </Grid>}

                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Paper >;
    }

    const getCompanyHeaderMobile = () => {
        return <Paper id='company-header' elevation={0}
            sx={{
                marginY: '0px', marginX: '0px',
                backgroundColor: '#F9F9F9',
                border: '1px solid #EBEBEB', borderRadius: '8px',
            }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', padding: '24px' }}>
                <Grid container spacing={0.1}>
                    <Grid item container sm={12} md={9} sx={{ display: 'flex', flexDirection: "row" }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', padding: '5px', }}>
                            {logoBox}
                        </Box>
                        <Box sx={{
                            display: 'flex', padding: { md: "0px 24px", xs: "0px 8px" },
                            maxWidth: { xs: "calc(100% - 100px)", md: "auto" }, height: '0px'
                        }}>
                            <Box >
                                <Box flexDirection="row" display="flex" >
                                    <Typography color="#12626C" sx={{ fontSize: { xs: "14px", md: '16px' }, fontWeight: '700' }} variant="h6">
                                        {companyShort?.companyName}
                                    </Typography>
                                    <CopyLinkButton style={{ paddingY: "0px" }} locationHash='' linkToCopy={getCompanyPreviewUrl} />
                                </Box>
                                <Typography sx={{ color: "#686868", fontSize: { md: "12px", xs: "10px" } }}>
                                    {companyShort?.companySubCategory} , {companyShort?.registeredState}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item container sm={3} md={3} sx={{ justifyContent: "right" }}>
                        <Box sx={{ display: "flex", justifyContent: { md: 'right', xs: 'right' }, width: '100%', direction: "column", gridAutoFlow: "column" }}>
                            <Grid item xs={12} sx={{ justifyContent: 'flex-end' }}>
                                <CardActions style={{ padding: "0px 0px 0px 0px" }} sx={{ maxWidth: '40px', }} >
                                    {(!((owner?.id || '') === (currentUser?._id || ''))) && <Box>
                                        <Button
                                            onClick={toggleFollow}
                                            sx={{ border: '1px solid', paddingLeft: "20px", fontWeight: 700, wordWrap: 'normal', fontSize: "14px" }}
                                            variant="outlined"
                                            startIcon={<AddIcon />}>
                                            {isFollow ? 'Unfollow' : 'Follow'}
                                        </Button>
                                    </Box>}
                                    {(!(owner && owner.id?.length > 0)) && <Box >
                                        <Button
                                            onClick={onOwnCompanyClick}
                                            sx={{
                                                fontSize: '10px',
                                                padding: "0px 0px",
                                                fontWeight: "700", height: "40px"
                                            }}
                                            size="small"
                                            variant="contained">
                                            Own This Page
                                        </Button>
                                    </Box>}
                                    {(owner && owner.id?.length > 0) && <Tooltip title={"Stockknocks Verified"}>
                                        <Box>
                                            <VerifiedIcon color="primary" />
                                        </Box>
                                    </Tooltip>}
                                </CardActions>
                            </Grid>
                            {price &&
                                <React.Fragment>
                                    <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', marginBottom: '4px', justifyContent: 'right', margin: '0px', height: "40px" }} maxWidth={{ xs: '40px' }}>
                                        <Box sx={{ height: "50px" }}>
                                            <Button
                                                onClick={onPageRedirectClick}
                                                sx={{ fontSize: '14px', padding: "0px 5px", fontWeight: "700", height: "40px" }}
                                                size="small"
                                                variant="contained">Trade</Button>
                                        </Box>
                                    </Grid>
                                    <Grid container spacing={0} sx={{ display: 'flex' }} maxWidth={{ xs: '50px' }}>
                                        <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end', margin: '0px' }}>
                                            <Typography
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    fontWeight: '700',
                                                    fontSize: { md: "16px", xs: "12px" },
                                                    color: '#0f9764'
                                                }}
                                            >
                                                {`+ ${price?.buy_price || "NA"}`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', justifyContent: 'right', margin: '0px' }}>
                                            <Typography
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    fontWeight: '700',
                                                    fontSize: { md: "16px", xs: "12px" },
                                                    color: '#fb645c'
                                                }}>
                                                {` - ${price?.sell_price || "NA"}`}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            }
                        </Box>
                        {price && <Grid item xs={14} sm={14} md={12} sx={{ display: 'flex', justifyContent: 'right', marginTop: '5px' }}>
                            <Typography
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    fontWeight: '400', fontSize: '10px', fontStyle: 'normal',
                                    lineHeight: "13px",
                                    color: '#686868',
                                }}
                            >
                                {`Price Date: ${DateExtensions.getDateStringUppercaseMonthFromString(price.updatedAt, ' ', 'NA')}`}
                            </Typography>
                        </Grid>}
                    </Grid>
                </Grid>
            </Box>
        </Paper >;

    }



    return loading ? <React.Fragment>{CircularIndeterminate()} </React.Fragment> : <Box className="page" style={{ position: "relative" }}>
        {isSM ? getCompanyHeader() : getCompanyHeaderMobile()}
        <Paper id='company-section-header' elevation={0} className={classes.card}
            sx={{
                border: '1px solid #EBEBEB',
                maxHeight: '100px',
                height: (mainTab === "basic_details") ? '100px' : '69px',
            }}>
            <InView as="div" id='in-view-sticky-box'
                threshold={0}
                // root={document.querySelector<HTMLElement>("#main-tab")}
                rootMargin={`-${appBarBottom + 3}px 0px 0px 0px`}
                trackVisibility={false}
                delay={0}
                initialInView={true}
                fallbackInView={true}
                onChange={(inView, entry) => stickyTabHandler(inView, entry)}>
                <Box id="main-tab"
                    sx={{
                        paddingTop: "16px",
                        marginRight: "0px",
                        zIndex: 3,
                    }}
                >
                    <CardActions sx={{ paddingY: "0px" }}>
                        <Button
                            sx={{ fontSize: '16px', padding: "10px 16px", fontWeight: "700", height: "40px" }}
                            size="small"
                            variant={mainTab === "basic_details" ? "contained" : "outlined"}
                            onClick={(e) => handleMainTabChange(e, "basic_details")}
                        >{isSM ? 'Overview' : 'Overview'}</Button>
                        <Button
                            sx={{ fontSize: '16px', padding: "10px 16px", fontWeight: "700", height: "40px", marginLeft: "24px!important" }}
                            size="small"
                            variant={mainTab === "posts" ? "contained" : "outlined"}
                            onClick={(e) => handleMainTabChange(e, "posts")}
                        >Posts</Button>
                        {(owner && owner.id?.length > 0) &&
                            <Button
                                sx={{ fontSize: '16px', padding: "10px 16px", fontWeight: "700", height: "40px", marginLeft: "24px!important" }}
                                size="small"
                                variant={mainTab === "website" ? "contained" : "outlined"}
                                onClick={(e) => handleMainTabChange(e, "website")}
                            >Website</Button>}
                        <Button
                            sx={{ fontSize: '16px', padding: "10px 16px", fontWeight: "700", height: "40px", marginLeft: "24px!important" }}
                            size="small"
                            variant={mainTab === "video" ? "contained" : "outlined"}
                            onClick={(e) => handleMainTabChange(e, "video")}
                        >Videos</Button>
                    </CardActions>
                </Box>
            </InView>
            <Box id="child-tab"
                sx={{
                    width: '100%', typography: 'body1',
                    display: 'flex',
                    marginX: '5px',
                    marginY: '2px',
                    height: '42px',
                    maxHeight: '42px'
                }}>
                <TabContext value={mainTab}>
                    <Box sx={{ borderBottom: 0, borderColor: 'divider', display: 'inline-grid' }}>
                        {
                            (mainTab === "basic_details") &&
                            <Tabs
                                sx={{ marginBottom: 0, height: '100%', minHeight: '100%' }}
                                value={childTab}
                                variant="scrollable"
                                scrollButtons={false}
                                textColor="inherit"
                                indicatorColor={"primary"}
                                TabIndicatorProps={{ style: { height: "3px" } }}
                                allowScrollButtonsMobile
                                onChange={handleChildTabChange}
                                aria-label="child-tabs">
                                <Tab disableRipple sx={{ minHeight: '18px', textTransform: 'capitalize', fontWeight: 700, fontSize: "14px", paddingBottom: "0px" }} label={<Link color={"#686868"} underline="none" href="#tabdetail-basicdetails">Basic Details</Link>} value="basicdetails" />
                                {((!loading) && companySummary?.directors_summary?.directors?.length > 0) &&
                                    <Tab disableRipple sx={{ minHeight: '18px', textTransform: 'capitalize', fontWeight: 700, fontSize: "14px", paddingBottom: "0px" }} label={<Link color={"#686868"} underline="none" href="#tabdetail-directors">Directors</Link>} value="directors" />}
                                {((!loading) && (companySummary?.charges_summary?.open_charges?.length > 0 || companySummary?.charges_summary?.closed_charges?.length)) &&
                                    <Tab disableRipple sx={{ minHeight: '18px', textTransform: 'capitalize', fontWeight: 700, fontSize: "14px", paddingBottom: "0px" }} label={<Link color={"#686868"} underline="none" href="#tabdetail-charges">Charges</Link>} value="charges" />}
                                {showFinanceTab && <Tab disableRipple sx={{ minHeight: '18px', textTransform: 'capitalize', fontWeight: 700, fontSize: "14px", paddingBottom: "0px" }} label={<Link color={"#686868"} underline="none" href="#tabdetail-financials">Financials</Link>} value="financials" />}
                                {((!loading) && companySummary?.ratings_summary?.ratings?.length > 0) &&
                                    <Tab disableRipple sx={{ minHeight: '18px', textTransform: 'capitalize', fontWeight: 700, fontSize: "14px", paddingBottom: "0px" }} label={<Link color={"#686868"} underline="none" href="#tabdetail-ratings">Ratings</Link>} value="ratings" />}
                                {((companyFinanceS?.has_data || companyFinanceC?.has_data)) &&
                                    <>
                                        <Tab disableRipple sx={{ minHeight: '18px', textTransform: 'capitalize', fontWeight: 700, fontSize: "14px", paddingBottom: "0px" }} label={<Link color={"#686868"} underline="none" href="#tabdetail-balancesheet">Balance Sheet</Link>} value="balancesheet" />
                                        <Tab disableRipple sx={{ minHeight: '18px', textTransform: 'capitalize', fontWeight: 700, fontSize: "14px", paddingBottom: "0px" }} label={<Link color={"#686868"} underline="none" href="#tabdetail-profitandloss">Profit And Loss</Link>} value="profitandloss" />
                                        <Tab disableRipple sx={{ minHeight: '18px', textTransform: 'capitalize', fontWeight: 700, fontSize: "14px", paddingBottom: "0px" }} label={<Link color={"#686868"} underline="none" href="#tabdetail-ratios">Ratios</Link>} value="ratios" />
                                    </>
                                }
                                <Tab disableRipple sx={{ minHeight: '18px', textTransform: 'capitalize', fontWeight: 700, fontSize: "14px", paddingBottom: "0px" }} label={<Link color={"#686868"} underline="none" href="#more-details">More Details</Link>} value="moredetails" onClick={onMoreDetailsClick} />
                            </Tabs>
                        }
                    </Box>
                </TabContext>
            </Box>
        </Paper>
        <Card ref={refContent} classes={{ root: 'informationBlockGray' }} sx={{ marginTop: '24px', boxShadow: 'none' }}>
            <Box>
                <TabContext value={mainTab}>
                    <TabPanel sx={{ padding: '0px', color: '#FFFFFF', backgroundColor: '#FFFFFF' }} value="basic_details">
                        <GetUnlistedBasicDetails companyShort={companyShort} companySummary={companySummary} balanceSheetSummaryS={companyFinanceS} balanceSheetSummaryC={companyFinanceC} type={displayType} loading={companyFinanceLoading} />
                        <Box sx={{ marginTop: "20px", "&>div": { background: "#F9F9F9", border: '1px solid #EBEBEB', textAlign: "center", color:'rgba(0, 0, 0, 0.87)' } }}>
                            <StockKnocksGrid
                                title='Directors Details:'
                                columns={[{ name: 'DIN', title: 'Din', minWidth: "100px" },
                                { name: 'NAME', title: 'Director Name' },
                                { name: 'DESIGNATION', title: 'Designation' },
                                { name: 'DATE_JOIN', title: 'Date of Appointment', formatter: (d) => new Date(d).toLocaleDateString('hi') }]}
                                data={companyShort.directors || []}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel sx={{ padding: '0px', color: '#FFFFFF', backgroundColor: '#FFFFFF' }} value="posts">
                        <PostSectionComponent co_id={code || ''} co_userid={owner?.id || ''} />
                    </TabPanel>
                    <TabPanel sx={{ padding: '0px', color: '#FFFFFF', backgroundColor: '#FFFFFF' }} value="website">
                        {getContent("website")}
                    </TabPanel>
                    <TabPanel sx={{ padding: '0px', color: '#FFFFFF', backgroundColor: '#FFFFFF' }} value="video">
                        {getContent("video")}
                    </TabPanel>
                </TabContext>
                <MobileAdvert />
            </Box>
        </Card>

        {
            showPageRedirectionDialog && <PageRedirectionDialog
                isOpen={showPageRedirectionDialog}
                handleClose={handlePageRedirectionDialogClose}
                message={'You are being redirected to another website ? Do you want to continue ?'}
                btnText={'Confirm'}
                handleConfirm={handleConfirmClick}
            />
        }
        {
            showMoreDetailsDialog && <PageRedirectionDialog
                isOpen={showMoreDetailsDialog}
                handleClose={handleMoreDetailsDialogClose}
                message={'You are being redirected to another website ? Do you want to continue ?'}
                btnText={'Confirm'}
                handleConfirm={handleMoreDetailsClick} />
        }
        {
            showOwnCompanyDialog && <OwnCompanyDialog
                isOpen={showOwnCompanyDialog}
                handleClose={handleOwnCompanyDialogClose}
                message={'Own Your Company Request Here'}
                btnText={'Confirm'}
                // handleConfirm={handleMoreDetailsClick}
                companyCin={companyShort?.CIN}
                companyName={companyShort?.companyName}
            />
        }
    </Box>;
}

export default UnlistedCompanyOverview;